import React from 'react';
import lax from 'lax.js';
import { Link } from 'react-router-dom';
import LaxButton from '../Shared/LaxButton';

import { Element } from 'react-scroll'


class About extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()

        document.addEventListener('scroll', function (x) {
            lax.update(window.scrollY)
        }, false)

        lax.update(window.scrollY)
    }

    render() {
        return (

            <Element id='section1' name='section1'>

                <section className="about-area ptb-120 bg-image">
                    <div className="container">
                        <div className="row h-100 align-items-center">
                            <div className="col-lg-6">
                                <div className="about-content">
                                    <span>Souhaitez-vous assurer un suivi rapproché de vos bovins 24/7 ? souhaitez-vous avoir des informations en temps réel sur leur santé et en être informé précocement pour une intervention efficiente ?

</span>

                                    <h2> <b>Cattle Edge</b> , le nouveau-né des solutions digitales  <b> d’AgriEdge </b> , sera votre partenaire.</h2>



                                    <p>Les données collectées en temps réel sont transformées, grâce à notre modèle analytics, en information à très forte valeur ajoutée accessible via une plateforme digitale.</p>

                                    <p>Sur cette plateforme digitale, vous trouverez l’ensemble du troupeau avec des informations sur chacun de vos bovins.

</p>

                                    <div className="signature">
                                        <img src={require("../../assets/images/signature.png")} alt="signature" />
                                    </div>


                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="about-image">
                                    <img src={require("../../assets/images/about1.jpg")} className="about-img1" alt="about" />
                                    <img src={require("../../assets/images/about2.jpg")} className="about-img2" alt="about" />
                                    <img src={require("../../assets/images/shapes/5.png")} className="shape-img" alt="about" />

                                    <LaxButton to="#/contact" buttonText="Contactez-nous" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </Element>

        );
    }
}

export default About;