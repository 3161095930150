import React from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import logo from '../../assets/images/logo.png';

 
class MainBanner extends React.Component {
    state = {
        days: '',
        hours: '',
        minutes: '',
        seconds: '',
        isOpen: false
    };

    openModal = () => {
        this.setState({ isOpen: true })
    }

    makeTimer = () => {
        let endTime = new Date("August 23, 2021 17:00:00 PDT");
        let endTimeParse = (Date.parse(endTime)) / 1000;
        let now = new Date();
        let nowParse = (Date.parse(now) / 1000);
        let timeLeft = endTimeParse - nowParse;
        let days = Math.floor(timeLeft / 86400);
        let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
        let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600)) / 60);
        let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
        if (hours < "10") { hours = "0" + hours; }
        if (minutes < "10") { minutes = "0" + minutes; }
        if (seconds < "10") { seconds = "0" + seconds; }
        this.setState({
            days, hours, minutes, seconds
        });
    }

    componentDidMount() {
        setInterval(() => {
            this.makeTimer();
        }, 1000);
    }

    scrollToAbout = () => {
        const anchor = document.querySelector('#section1')
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })          
    }

    render() {
        return (
            <div className="main-banner video-banner">


                <video loop muted autoPlay poster="#" className="video-background">
                    <source src={require("../../assets/video/Unt.webm")} type="video/mp4" />
                </video>

                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="main-banner-content banner-content-center">


                                <p> Souhaitez-vous assurer un suivi rapproché de vos bovins   <span>24/7 </span> ?</p>

                                <Link className="navbar-brand" to="/">
                                    <img src={logo} alt="logo" />
                                </Link>
                                <h1>Votre Solution pour un  <span>Élevage de Précision   </span></h1>
                                <ul>
                                    <li><i className="icofont-clock-time"></i> Information en temps réel </li>
                                    <li><i className="icofont-medical-sign"></i>  Intervention efficiente</li>
                                </ul>
                                <div className="button-box">


                                     <Link onClick={this.scrollToAbout} className="btn btn-primary">   voir plus </Link> 
 
                                     


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ModalVideo
                    channel='youtube'
                    isOpen={this.state.isOpen}
                    videoId='cRXm1p-CNyk'
                    onClose={() => this.setState({ isOpen: false })}
                />



                <div className="shape1">
                    <img src={require("../../assets/images/shapes/1.png")} alt="shape1" />
                </div>
                <div className="shape2 rotateme">
                    <img src={require("../../assets/images/shapes/2.png")} alt="shape2" />
                </div>
                <div className="shape3 rotateme">
                    <img src={require("../../assets/images/shapes/3.png")} alt="shape3" />
                </div>
                <div className="shape4">
                    <img src={require("../../assets/images/shapes/4.png")} alt="shape4" />
                </div>
            </div>
        );
    }
}

export default MainBanner;