import React from 'react';
import { Link } from 'react-router-dom';
 
class Footer extends React.Component {
    render(){
        return (
            <footer className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="single-footer-widget">
                                <h3> Emplacement</h3> 
                                <p className="location">
                                    <i className="icofont-google-map"></i>La ville verte benguerir
                                </p>
                                <Link to="#" className="contact-authority">
                                    <i className="icofont-phone"></i>  0666-105841
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="single-footer-widget">
                                <h3> Réseaux sociaux</h3>
                                 
                                <ul className="social-links">
                                    <li><Link to="//web.facebook.com/AgriEdge-108563827480054/" className="facebook" target="_blank"><i className="icofont-facebook"></i></Link></li>
                                     <li><Link to="https://www.linkedin.com/in/agriedge-bu-l-precision-agricult/" className="linkedin" target="_blank"><i className="icofont-linkedin"></i></Link></li>
                                 </ul>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="copyright-area">
                                <div className="logo">
                                    <Link to="index.html">
                                        <img src={require("../../assets/images/logo.png")} alt="logo" />
                                    </Link>
                                </div>
                                <p>
                                    Copyright <i className="icofont-copyright"></i>2020 Agriedge. All rights reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
 
export default Footer;