import React from "react";
import { Link } from "react-router-dom";
import "isomorphic-fetch";
import Footer from "../Common/Footer";
import Swal from 'sweetalert2'
import emailjs from 'emailjs-com';


class Contact extends React.Component {
  state = {
    submitting: false,
    submitted: false,
    buttonState: "",
    formFields: {
      name: "",
      email: "",
      phone: "",
      text: ""
    }
  };
 

  onSubmit = e => { 
    e.preventDefault()

    const { name, email, phone, text } = this.state.formFields; 
    let templateParams = {
      name: name,
      to_name: '<brahim.elbouziani@gmail.com>',
      email: email,
      phone: phone,
      message: text,
     }
     emailjs.send(
      'gmail',
      'template_TTLirDJH',
       templateParams,
      'user_RDYwGedvxASFcUHIKVYl9'
     ) 

     // Email successfully sent alert
     Swal.fire({
      title: 'email envoyé avec succès',
      icon: 'success'
    })

    let formFields = Object.assign({}, this.state.formFields);
    formFields.name = "";
    formFields.email = "";
    formFields.phone = "";
    formFields.text = "";
    this.setState({ formFields });
    
   };
  

  sendFeedback = (templateId, variables) => {


    emailjs.send(
      'gmail', templateId,
      variables
      ).then(res => {
        // Email successfully sent alert
        Swal.fire({
          title: 'Email Successfully Sent',
          icon: 'success'
        })
      })
      // Email Failed to send Error alert
      .catch(err => {
        Swal.fire({
          title: 'Email Failed to Send',
          icon: 'error'
        })
        console.error('Email Error:', err)
      })
  }


  nameChangeHandler = e => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.name = e.target.value;
    this.setState({ formFields });
  };

  emailChangeHandler = e => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.email = e.target.value;
    this.setState({ formFields });
  };

  phoneChangeHandler = e => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.phone = e.target.value;
    this.setState({ formFields });
  };

  textChangeHandler = e => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.text = e.target.value;
    this.setState({ formFields });
  };

  onHideSuccess = () => {
    this.setState({ submitted: false });
  };

  successMessage = () => {
    if (this.state.submitted) {
      return (
        <div
          className="alert alert-success alert-dismissible fade show"
          style={{ marginTop: "14px" }}
        >
          <strong>Thank you!</strong> Your message is send to the owner.
          <button type="button" className="close" onClick={this.onHideSuccess}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-title-area item-bg1">
          <div className="container">
            <h1>Contactez-nous</h1>
            <span>Pour plus d’informations sur Cattle Edge </span>
            <ul>
              <li>
                <Link to="/">Accueil</Link>
              </li>
              <li>Contact</li>
            </ul>
          </div>
        </div>

        <section className="contact-area ptb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="contact-box">
                  <div className="icon">
                    <i className="icofont-phone"></i>
                  </div>

                  <div className="content">
                    <h4>Tel </h4>
                    <p>(+212) 666-105841</p>

                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="contact-box">
                  <div className="icon">
                    <i className="icofont-email"></i>
                  </div>

                  <div className="content">
                    <h4>E-mail</h4>
                    <p>AgriEdge@um6p.ma</p>

                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div className="contact-box">
                  <div className="icon">
                    <i className="icofont-world"></i>
                  </div>

                  <div className="content">
                    <h4>Emplacement</h4>
                    <p>
                      La ville verte benguerir
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row h-100 align-items-center contact-form">
              <div className="col-lg-4 col-md-12">
                <div className="leave-your-message">
                  <h3>Cattle Edge</h3>
                  <p>
                    Cattle Edge, le nouveau-né des solutions digitales d’AgriEdge
                  </p>

                  <div className="stay-connected">
                    <h3>Rester Connecté</h3>
                    <ul>
                      <li>
                        <Link to="//www.linkedin.com/in/agriedge-bu-l-precision-agricult/" target="_blank">
                          <i className="icofont-linkedin"></i>
                          <span>linkedin</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 col-md-12">
                <form onSubmit={this.onSubmit} id="contactForm">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Nom*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          required={true}
                          data-error="S'il vous plaît entrez votre nom"
                          value={this.state.formFields.name}
                          onChange={this.nameChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label htmlFor="email">Email*</label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          required={true}
                          data-error="S'il vous Veuillez saisir votre email"
                          value={this.state.formFields.email}
                          onChange={this.emailChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="number">Numéro de téléphone*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="number"
                          id="number"
                          required={true}
                          data-error="Veuillez saisir votre numéro"
                          value={this.state.formFields.phone}
                          onChange={this.phoneChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="message">Message*</label>
                        <textarea
                          name="message"
                          className="form-control"
                          id="message"
                          cols="30"
                          rows="4"
                          required={true}
                          data-error="Écrivez votre message"
                          value={this.state.formFields.text}
                          onChange={this.textChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <button type="submit" className="btn btn-primary">
                        Envoyer le message
                      </button>
                      <div id="msgSubmit" className="h3 text-center hidden">
                        {this.successMessage()}
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Contact;
