import React from 'react';
import { Link } from 'react-router-dom';

class WhyUs extends React.Component {
    render() {
        return (
            <section className="why-choose-us">
                <div className="row m-0">
                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-thief-alt"></i>
                                        </div>
                                        <h3> Contre le vol  </h3>
                                        <p>Protéger votre bovin contre le vol </p>
                                        <Link to="#"  ></Link> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-notification"></i>
                                        </div>
                                        <h3>Limites de la ferme/parcours</h3>
                                        <p>Être notifié en temps réel quand vos bovins dépassent les limites de la ferme/parcours .</p>
 

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-brand-target"></i>
                                        </div>
                                        <h3>  économiser les coûts  </h3>
                                        <p> Prédire les cas de gestation et économiser les coûts des tests de gestations prématurés </p>
 
                                  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-warning"></i>
                                        </div>
                                        <h3> Alertes </h3>
                                        <p> Monitorer le vêlage via des alertes de détresse pré-vêlage et post-vêlage </p>
                                        <Link to="#"  ></Link>

                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-medical-sign"></i>
                                        </div>
                                        <h3>Suivre l'efficacité</h3>
                                        <p>Détecter précocement les problèmes de santé et suivre mieux l'efficacité des traitements vétérinaires</p>
 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-verification-check"></i>
                                        </div>
                                        <h3>  Monitorer le vêlage </h3>
                                        <p> Monitorer le vêlage via des alertes
                                             de détresse pré-vêlage et post-vêlage  </p> 

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


 

                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-bullseye"></i>
                                        </div>
                                        <h3>  Suivre la chaleur</h3>
                                        <p>   Suivre la chaleur de vos bovins  </p> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-bullseye"></i>
                                        </div>
                                        <h3>   Détecter les problèmes </h3>
                                        <p>  Détecter les problèmes de propagation des maladies,
                                             la panique et le stress au sein du troupeau</p> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <ul className='slideshow'>
                    <li><span>fffffffffffffffffffff</span></li>
                    <li><span></span></li>
                    <li><span></span></li>
                    <li><span></span></li>
                </ul>
            </section>
        );
    }
}

export default WhyUs;