import React from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel3';
import lax from 'lax.js';
import LaxDiv from '../Shared/LaxDiv';

const options = {
    loop: true,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
        "<i class='icofont-rounded-left'></i>",
        "<i class='icofont-rounded-right'></i>"
    ],
    responsive:{
        0: {
            items:1,
        },
        768: {
            items:2,
        },
        1200: {
            items:4,
        }
    }
}
 
class LatestNews extends React.Component {
	constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }
    render(){
        return (


             

            <section id='why' name='why'  className="blog-area ptb-120 bg-image">
                <div className="container">
                    <div className="section-title">
                        <span>Pourquoi nous choisir ?</span>
                        <h2>Les fonctionnalités disponibles sur  : <b>Cattle Edge </b></h2>
                        <LaxDiv text="AgriEdge" dataPreset="driftLeft" /> 
                        <div className="bar"></div>
                    </div>

                    <div className="row">
                        <OwlCarousel 
                            className="blog-slides owl-carousel owl-theme"
                            {...options}
                        >
                            <div className="col-lg-12 col-md-auto">

                                <div className="single-blog-post">
                                    <div className="blog-image">
                                        <Link to="#"><img src={require("../../assets/images/thef.png")} alt="blog" /></Link>
                                    </div>

                                    <div className="blog-post-content">
                                        <h3><Link to="#">Contre le vol</Link></h3>
                                        <p>Protéger votre bovin contre le vol</p>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-12 col-md-auto">
                                <div className="single-blog-post">
                                    <div className="blog-image">
                                        <Link to="#"><img src={require("../../assets/images/radar.png")} alt="blog" /></Link> 
                                    </div>
                                    <div className="blog-post-content">
                                        <h3><Link to="#">Limites de la ferme/parcours</Link></h3>
                                        <p>Être notifié en temps réel quand vos bovins dépassent les limites de la ferme/parcours .</p> 
                                     </div>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-auto">
                                <div className="single-blog-post">
                                    <div className="blog-image">
                                        <Link to="#"><img src={require("../../assets/images/see.png")} alt="blog" /></Link>

                                      
                                    </div>

                                    <div className="blog-post-content">
                                        
                                        <h3><Link to="#">Economiser les coûts </Link></h3>
                                        <p>Prédire les cas de gestation et économiser
                                             les coûts des tests de gestations prématurés</p>
                                             </div>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-auto">
                                <div className="single-blog-post">
                                    <div className="blog-image">
                                        <Link to="#"><img src={require("../../assets/images/alert.png")} alt="blog" /></Link>

                                    
                                    </div>

                                    <div className="blog-post-content">
                                         <h3><Link to="#">Alertes</Link></h3>


                                        <p>Monitorer le vêlage via des alertes de détresse pré-vêlage et post-vêlage</p>



                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-12 col-md-auto">
                                <div className="single-blog-post">
                                    <div className="blog-image">
                                        <Link to="#"><img src={require("../../assets/images/bagra.png")} alt="blog" /></Link>
 
                                    </div>

                                    <div className="blog-post-content">


                                        <h3><Link to="#">Suivre l'efficacité
</Link></h3>
                                        <p>Détecter précocement les problèmes de santé et suivre mieux l'efficacité des traitements vétérinaires

</p>


                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-auto">
                                <div className="single-blog-post">
                                    <div className="blog-image">
                                        <Link to="#"><img src={require("../../assets/images/doc.png")} alt="blog" /></Link>

                                     
                                     
                                    </div>

                                    <div className="blog-post-content">
                                        
                                        <h3><Link to="#">Monitorer le vêlage</Link></h3>
                                        <p>Monitorer le vêlage via des alertes de détresse pré-vêlage et post-vêlage
</p>
 
 
                                     </div>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-auto">
                                <div className="single-blog-post">
                                    <div className="blog-image">
                                        <Link to="#"><img src={require("../../assets/images/chaleur.png")} alt="blog" /></Link>

                                  
                                    </div>

                                    <div className="blog-post-content">
                                        
                                        <h3><Link to="#">Suivre la chaleur
</Link></h3>
                                        <p>Suivre la chaleur de vos bovins

</p>
                                    
                                   
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-auto">
                                <div className="single-blog-post">
                                   
                                    <div className="blog-image">
                                        <Link to="#"><img src={require("../../assets/images/suivi.png")} alt="blog" /></Link>

                             
                                    </div>

                                    <div className="blog-post-content">
                                         <h3><Link to="#">Détecter les problèmes
</Link></h3>
                                        <p>Détecter les problèmes de propagation des maladies, la panique et le stress au sein du troupeau

</p>
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </section>
           

     
        );

    }
}
 
export default LatestNews;